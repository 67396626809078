<template>
    <div v-if="$can('read','probation')">
        <span v-if="error"> {{ error }}</span>
        <b-card v-if="userProbation.status === 'Accepted' || userProbation.status === 'Rejected'">
            <p class="text-primary"><strong> User Probation has been <span :class="userProbation.status === 'Accepted' ? 'text-primary' : 'text-danger'">{{ userProbation.status }}</span></strong></p>
            <p>
               <strong>Comments: </strong> {{ userProbation.extendReason || userProbation.comments }}
            </p>
        </b-card>
        <!-- <b-card v-else-if="userProbation.status === 'Rejected'">
            <p class="text-primary"><strong> User Probation has been Rejected</strong></p>
            <p>
               <strong>Comments: </strong> {{ userProbation.comments }}
            </p>
        </b-card> -->
        <b-card v-else-if="!userProbation.status || userProbationExtended">
            <div v-if="userProbationExtended" class="mb-2">
                <p class="text-primary"><strong> User Probation has been<span :class="userProbation.status === 'Accepted' ? 'text-primary' : 'text-danger'"> {{ userProbationExtended.status }}</span></strong></p>
                <p>
                    <strong>Comments: </strong> {{ userProbationExtended.comments }}
                </p>
                <hr>
            </div>
            
            <h5 class="text-primary"><strong>Update Probation Details for {{ userProbation.name }}</strong></h5>
            <b-row class="mt-1" >
                <b-col  md="4" xl="4" xs="4" >
                    <b-form-group
                        label="Select Status"
                        label-for="select-status">
                        <v-select
                            v-model="userProbation.statusSelected"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="title"
                            :options="userProbationStatusOptions"
                            :reduce="(option) => option.title"
                            @input="manageDate()"
                        />
                    </b-form-group>    
                </b-col>
                <b-col col v-if="userProbation.statusSelected === 'Extended'">
                    <b-form-group
                        label="Select Start Date"
                        label-for="start-date">
                        <flat-pickr
                            v-model="userProbation.newStartDate"
                            class="form-control"
                            :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
                            placeholder="Select Start Date"
                        />
                    </b-form-group>

                </b-col>  
                <b-col col v-if="userProbation.statusSelected === 'Extended'">
                    <b-form-group
                        label="Select End Date"
                        label-for="end-date">
                        <flat-pickr
                            v-model="userProbation.newEndDate"
                            class="form-control"
                            :config="{ enableTime: false,dateFormat: 'Y-m-d', minDate: 'today'}"
                            placeholder="Select End Date"
                        />
                    </b-form-group>
                        
                </b-col>
                <b-col
                    cols="12"
                    xs="12"
                    class="mb-2"
                    v-if="userProbation.statusSelected"
                >
                    <label for="textarea-default">Add Comments *</label>
                    <b-form-textarea
                        id="textarea-default"
                        v-model="userProbation.extendReason"
                        placeholder="Add Notes"
                        rows="3"
                    />
              </b-col>
              
            </b-row>
            <b-button variant="primary" @click="updateProbation()">Submit</b-button>
            
            
            
      </b-card>
      <b-card v-if="userProbation">
        <b-card-title><h5 class="text-primary">User Details</h5></b-card-title>
        <b-card-text>
            
            <b-row class="mt-1">
                <b-col>Name:</b-col><b-col> <strong>{{ userProbation.name }}</strong></b-col>
                <b-col>Manager Name:</b-col><b-col> <strong>{{ userProbation.manager }}</strong></b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col>EmpId:</b-col><b-col> <strong>{{ userProbation.emp_id }}</strong></b-col>
                <b-col>MgrId:</b-col><b-col> <strong>{{ userProbation.mgr_id }}</strong></b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col>Email:</b-col><b-col> <strong>{{ userProbation.email }}</strong></b-col>
                <b-col>Mgr Email:</b-col><b-col> <strong>{{ userProbation.mgr_email }}</strong></b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col>Contact No:</b-col><b-col> <strong>{{ userProbation.contact_no }}</strong></b-col>
                <b-col>Mgr Contact No:</b-col><b-col> <strong>{{ userProbation.mgr_contact_no }}</strong></b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col>DOJ:</b-col><b-col> <strong>{{ userProbation.doj }}</strong></b-col>
                <b-col>Probation Days:</b-col><b-col> <strong>{{ userProbation.probation_days }}</strong></b-col>
                <b-col>End Date:</b-col><b-col> <strong>{{ userProbation.end_date }}</strong></b-col>
            </b-row>
        </b-card-text>
        </b-card>
    </div>
  </template>
  
  <script>
  import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, BCardText, BCardTitle, BFormGroup, BFormTextarea
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import flatPickr from 'vue-flatpickr-component'
  import userProbationService from '@/services/user-probation-service'
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  
  export default {
    data() {
      return {
        userProbation: {
            statusSelected: null,
            name: null,
            status: null,
            emp_id: null,
            name: null,
            manager: null,
            email: null,
            mgr_email: null,
            contact_no: null,
            mgr_contact_no: null,
            doj: null,
            probation_days: null,
            end_date: null,
            newStartDate: null,
            newEndDate: null,
            startDate: null,
            endDate: null,
            comments: null,
            extendReason: null,
        },
        error: null,
        userProbationStatusOptions: [
            {"title": "Accepted"},
            {"title": "Rejected"},
            {"title": "Extended"}
        ],
        emp_id: null,
        userProbationExtended: null
      }
    },  
    components: {
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      BCardText, BCardTitle, BFormGroup, BFormTextarea,
      flatPickr, 
      vSelect,
    },
    created() {
        
        // this.probationDays = this.$route.query.probation_days;
        // this.employee = this.$route.query.name;
    },
    methods: {
        updateProbation() {
            if(!this.$can('update', 'probation')) {
                return
            }
            if(!this.userProbation.statusSelected || !this.userProbation.extendReason) {
                window.alert("Probation Status & Comment is Mandatory")
                return
            } else if (this.userProbation.statusSelected === 'Extended') {
                if(!this.userProbation.newStartDate || !this.userProbation.newEndDate) {
                    window.alert("New Start Date & End Date is Mandatory")
                    return
                }
            }
            const userData = JSON.parse(localStorage.getItem('userData'));
            let data = null
            if(this.userProbation.statusSelected === 'Accepted' || this.userProbation.statusSelected === 'Rejected'){
                 data = {
                    status: this.userProbation.statusSelected,
                    start_date: this.userProbation.startDate,
                    end_date: this.userProbation.endDate,
                    comments: this.userProbation.extendReason,
                    extended: this.userProbation.status  === 'Extended' ? true : false,
                    // added_by: userData.emp_id,
                    updated_by: userData.emp_id
                }
            } else if (this.userProbation.statusSelected === 'Extended') {
                
                data = {
                    status: this.userProbation.statusSelected,
                    comments: this.userProbation.extendReason,
                    extended: this.userProbation.status  === 'Extended' ? true : false,
                    updated_by: userData.emp_id
                }
                console.log('in extended: ', data)
                const createProbation = {
                    start_date: this.userProbation.newStartDate,
                    end_date: this.userProbation.newEndDate,
                    extended: false,
                    emp_id: this.emp_id,
                    added_by: userData.emp_id,
                    updated_by: userData.emp_id
                }
                // if(!this.userProbation.statusSelected || !this.userProbation.extendReason) {
                //     window.alert("Probation Status & Comment is Mandatory")
                //     return
                // } else if (this.userProbation.statusSelected === 'Extended') {
                //     if(!this.userProbation.newStartDate || !this.userProbation.newEndDate) {
                //         window.alert("New Start Date & End Date is Mandatory")
                //         return
                //     }
                // } else {
                userProbationService.create(createProbation).then(res=>{
                    if(res.status === 201) {
                        this.userProbation.status = this.userProbation.statusSelected
                        this.userProbation.comments = this.userProbation.extendReason
                        this.userProbation.statusSelected = null
                        this.userProbation.end_date = this.userProbation.newEndDate
                        this.userProbation.extendReason = null
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "New Probation period added for user Successful!!!",
                                icon: "EditIcon",
                                variant: "primary",
                            },
                        });
                
                    } else if ((res.status === 401 ) || (res.status === 403 )) {
                        localStorage.clear();
                        this.$router.push({ name: "auth-login" });
                    } else if (res.status === 500 ){
                        this.error = 'Something went wrong. Please Contact Administrator'
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.error,
                                icon: "EditIcon",
                                variant: "danger",
                                timeout: 3000,
                            },
                        });
                    }
                })
                //}
                
            }
            if(!this.userProbation.statusSelected || !this.userProbation.extendReason) {
                window.alert("Probation Status & Comment is Mandatory")
                return
            } else if (this.userProbation.statusSelected === 'Extended') {
                if(!this.userProbation.newStartDate || !this.userProbation.newEndDate) {
                    window.alert("New Start Date & End Date is Mandatory")
                    return
                }
            } 
            userProbationService.update(this.userProbation.id, data).then(res => {
                if(res.status === 201) {
                    // this.userProbation = res.data.data
                    this.userProbation.status = this.userProbation.statusSelected
                    this.userProbation.comments = this.userProbation.extendReason
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Probation updated for user Successful!!!",
                            icon: "EditIcon",
                            variant: "primary",
                            timeout: 3000,
                        },
                    });
            
                } else if ((res.status === 401 ) || (res.status === 403 )) {
                    localStorage.clear();
                    this.$router.push({ name: "auth-login" });
                } else if (res.status === 500 ){
                    this.error = 'Something went wrong. Please Contact Administrator'
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: this.error,
                            icon: "EditIcon",
                            variant: "danger",
                            timeout: 3000,
                        },
                    });
                }
            })
            
            
        },
        manageDate() {
            if (this.userProbation.statusSelected === 'Extended') {
                this.userProbation.newStartDate = this.userProbation.end_date
                this.userProbation.newEndDate = null
            }
        }
    },
    mounted() {
        // Set the initial number of items
        // this.userProbation.statusSelected = null
        this.emp_id = this.$route.params.emp_id;
        
        userProbationService.getOne(this.emp_id).then(res => {
            console.log(res)
            if(res.status === 200) {
                if(res.data.data.length > 1) {
                    this.userProbationExtended = res.data.data[0]
                    this.userProbation = res.data.data[1]
                    if(this.$route.query.status) {
                        console.log('in route query', this.$route.query.status)
                        this.userProbation.statusSelected = this.$route.query.status
                        if (this.userProbation.statusSelected === 'Extended') {
                            this.userProbation.newStartDate = this.userProbation.end_date
                            this.userProbation.newEndDate = null
                        }
                    }
                } else {
                    this.userProbation = res.data.data[0]
                    if(this.$route.query.status) {
                        console.log('in route query', this.$route.query.status)
                        this.userProbation.statusSelected = this.$route.query.status
                        if (this.userProbation.statusSelected === 'Extended') {
                            this.userProbation.newStartDate = this.userProbation.end_date
                            this.userProbation.newEndDate = null
                        }
                    }
                }
                
                // this.totalUsers = this.userList.length
            } else if ((res.status === 401 ) || (res.status === 403 )) {
                localStorage.clear();
                this.$router.push({ name: "auth-login" });
            } else if (res.status === 500 ){
                this.error = 'Something went wrong. Please Contact Administrator'
            }
        })
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  </style>
  