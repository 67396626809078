import { render, staticRenderFns } from "./UsersProbation.vue?vue&type=template&id=668a14a2&scoped=true&"
import script from "./UsersProbation.vue?vue&type=script&lang=js&"
export * from "./UsersProbation.vue?vue&type=script&lang=js&"
import style0 from "./UsersProbation.vue?vue&type=style&index=0&id=668a14a2&lang=scss&scoped=true&"
import style1 from "./UsersProbation.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "668a14a2",
  null
  
)

export default component.exports